import React from "react";
import { domainReportClient } from "../../services/http";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@material-ui/core";

interface DomainInfoReport {
  domain: string;
  created: string;
  status: string;
  quality: DomainQuality;
  datalake?: DatalakReport | null;
  sitemap?: SitemapReport | null;
};

interface DomainQuality {
  last_updated: string;
  quality_score: number;
  version: string;
  exception_counts: Record<string, number>;
}

interface DatalakReport {
  count_total: number;
  count_by_provider: Record<string, number>;
  count_by_domain: Record<string, number>;
  recent_urls: string[];
  publish_date_history: Record<string, number[]>;
  download_date_history: Record<string, number[]>;
}

interface SitemapReport {
  count_total: number;
  recent_urls: string[];
}

interface DomainReportState {
  input: string;
  inputError: boolean;
  report?: DomainInfoReport;
}

const initialState: DomainReportState = {
  input: "",
  inputError: false,
  report: undefined,
};

type DomainReportActions =
  | { type: "EMPTY"; }
  | { type: "SET_INPUT"; input: string }
  | { type: "SET_INPUT_ERROR"; inputError: boolean }
  | { type: "SET_DOMAIN_REPORT"; input: string, domainReport: DomainInfoReport };
  
function reportsReducer(state: DomainReportState, action: DomainReportActions): DomainReportState {
  switch (action.type) {
    case "EMPTY":
      return {
        input: "",
        inputError: false,
        report: undefined,
      };
    case "SET_INPUT":
      return {
        ...state,
        input: action.input,
        inputError: false,
        report: undefined,
      };
    case "SET_INPUT_ERROR":
      return {
        ...state,
        inputError: action.inputError,
        report: undefined,
      };
    case "SET_DOMAIN_REPORT":
      if (action.input === state.input) {
        return {
          ...state,
          inputError: false,
          report: action.domainReport,
        };
      }
      return {
        ...state,
      }
    default:
      throw new Error("unkown action type " + action);
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      backgroundColor: "#f5f5f5",
    },
    tableContainer: {
      padding: 10,
      paddingRight: 20,
      overflowX: "auto",
    },
    table: {
      border: "2px solid #888888",
      minWidth: 700,
      padding: "10px",
    },
    topRow: {
      borderTop: "2px solid #888888",
    },
    leftCell: {
      borderRight: "2px solid #888888",
    },
  })
);

export default function DomainReport() {
  const [state, dispatch] = React.useReducer(reportsReducer, initialState);

  const runReport = async () => {
    try {
      if (!state.input) {
        dispatch({ type: "EMPTY" });
        return;
      }
      // load a local json file
      // const response  = await fetch("/amplify-domain-report.json");
      const response = await domainReportClient.get<DomainInfoReport>(
        `/${state.input}`, { validateStatus: () => true }
      )
      if (response.status === 400) {
        dispatch({ type: "SET_INPUT_ERROR", inputError: true });
        return;
      } else if (response.status === 200) {
        dispatch({ type: "SET_DOMAIN_REPORT", input: state.input, domainReport: response.data });
        return;
      } else if (response.status === 404) {
        const response = await domainReportClient.post<DomainInfoReport>(
          "/", { domain: state.input }
        );
        if (response.status === 200) {
          dispatch({ type: "SET_DOMAIN_REPORT", input: state.input, domainReport: response.data });
          return;
        } else {
          dispatch({ type: "SET_INPUT_ERROR", inputError: true });
          return;
        }
      }
    } catch (error) {
      console.error("Error fetching harvests:", error);
    }
  }

  const getExceptionCount = (exceptionCounts: Record<string, number>) => {
    var count = 0;
    if (!exceptionCounts) return count.toString();
    for (const key in exceptionCounts) {
      count += exceptionCounts[key];
    }
    return count.toString();
  }
  
  const classes = useStyles();

  const renderInput = (input: string, inputError: boolean) => {
    if (inputError) {
      return (
      <div>
        <TextField
          error label="Error"
          variant="outlined"
          value={input}
          onChange={(e) => dispatch({ type: "SET_INPUT", input: e.target.value })}
        />
        <Button disabled onClick={runReport}>Run</Button>
      </div>
      );
    }
    return (
      <div>
        <TextField
          variant="outlined"
          value={input}
          onChange={(e) => dispatch({ type: "SET_INPUT", input: e.target.value })}
        />
        <Button onClick={runReport}>Run</Button>
      </div>
    );
  }

  const renderSimpleValueTable = (
    rows: {
      label: string;
      value?: React.ReactNode;
    }[]
  ) => (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} size="small">
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} className={classes.topRow}>
              <TableCell>{row.label}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderBreakdownTable = (
    label: string,
    values?: Record<string, number>
  ) => {

    if (!values || (Object.keys(values).length === 0)) return (
      <></>
    );

    const entries = Object.entries(values);
    const [first, ...rest] = entries; // Destructure into first and rest

    return (
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size="small">
          <TableBody>
            <TableRow className={classes.topRow}>
              <TableCell className={classes.leftCell}>{label}</TableCell>
              <TableCell>{first?.[0]}</TableCell>
              <TableCell>{first?.[1]}</TableCell>
            </TableRow>
            {rest.map(([key, value]) => (
              <TableRow key={key}>
                <TableCell className={classes.leftCell}></TableCell>
                <TableCell>{key}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderListTable = (
    label: string,
    values?: string[]
  ) => {

    if (!values || (values.length === 0)) return (
      <></>
    );

    const [first, ...rest] = values; // Destructure into first and rest

    return (
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size="small">
          <TableBody>
            <TableRow className={classes.topRow}>
              <TableCell className={classes.leftCell}>{label}</TableCell>
              <TableCell colSpan={2}>{first}</TableCell>
            </TableRow>
            {rest.map((value) => (
              <TableRow>
                <TableCell className={classes.leftCell}></TableCell>
                <TableCell colSpan={2}>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  function showQaulityScore(quality?: number) {
    if (quality === undefined) return "N/A";
    return quality.toFixed(2);
  }

  function showStatus(report: DomainInfoReport) {
    if (report.datalake && report.sitemap) {
      return "complete";
    }
    const now = new Date();
    const created = new Date(report.created);
    const diff = now.getTime() - created.getTime();
    if (diff < 1000 * 60 * 15) {
      return "in progress";
    }
    return "complete";
  }

  const renderHistoryTable = (
    label: string,
    values?: Record<string, number[]>
  ) => {
    if (!values || (Object.keys(values).length === 0)) return (
      <></>
    );
    return (
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell colSpan={31}>{label}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(values).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                {value.map((v, index) => (
                  <TableCell key={index}>{v}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderReport = (s: DomainReportState) => {
    if (s.report) {
      return (
        <>
          <h2>Domain Report ({showStatus(s.report)})</h2>
          {renderSimpleValueTable([
            { label: "Source domain quality score", value: showQaulityScore(s.report.quality?.quality_score) },
            { label: "Number of URLs returned from source sitemap", value: s.report.sitemap?.count_total },
            { label: "Number of URLs in the datalake", value: s.report.datalake?.count_total },
            {
              label: "Number fo URLs rejected during enrichment",
              value: getExceptionCount(s.report.quality?.exception_counts)
            },
          ])}
          {renderBreakdownTable("Breakdown of rejection reasons (by count)", s.report.quality?.exception_counts)}
          {renderBreakdownTable("Provider breakdown of URLs in the datalake", s.report.datalake?.count_by_provider)}
          {renderBreakdownTable(
            "Associated Fully Qualified Domain Names breakdown",
            s.report.datalake?.count_by_domain
          )}
          {renderListTable(
            "Sample of URLs that exist in the datalake (top 10 most recent)", s.report.datalake?.recent_urls
          )}
          {renderListTable(
            "Sample of URLs that exist in source domain (top 10 most recent)", s.report.sitemap?.recent_urls
          )}
          {renderHistoryTable(
            "Publish date history",
            s.report.datalake?.publish_date_history
          )}
          {renderHistoryTable(
            "Download date history",
            s.report.datalake?.download_date_history
          )}
        </>
      );
    } else {
      return (
        <div />
      );
    }
  }

  return (
    <>
      {
        renderInput(state.input, state.inputError)
      }
      {
        renderReport(state)
      }
    </>
  );
}



